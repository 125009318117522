<template>
  <div>
    <!-- renders our app screens -->
    <router-view class="min-vh-100"/>
    <!-- slide in menu -->
    <b-sidebar id="sidebar-menu" shadow no-header width="100vw">
      <template>
        <Menu/>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import Menu from './components/Menu'
export default {
  name: 'App',
  components: {
    Menu
  },
  // calls the get user profile method on app load
  created() {
    // this.getUserProfile();
  },
  // sets page title for routes without a declared title
  watch: {
      '$route' (to) {
        document.title = to.meta.title || 'Mimi-App'
      }
    },
}
</script>

<style>

:root {
  --interaction-color: #ff9950;
  --danger-color: #ee684f;
  --warning-color: #ffc700;
  --text-icon-color: #ffffff;
  --notclickable-color: #cec6c6;
  --background-color: #3b3b3b;
  --secondary-text-icon-color: #a8a8a8;
  --visual-information-color: #346957;
  --disabled-color: #f4f4f4;
  --white-color: #ffffff;
}

h1 {
  font-size: 2.25rem;
  color: var(--text-icon-color);
  margin-top: 4.25rem;
}
h2 {
  font-size: 1.625rem;
  color: var(--text-icon-color);
  margin-top: 4.25rem;
  word-wrap: break-word;
}
h3 {
  font-size: 1.25rem;
  color: var(--text-icon-color);
}
h4 {
  font-size: 1.063rem;
  color: var(--text-icon-color);
}
body {
  font-size: 1rem;
  color: var(--text-icon-color);
  background-color: var(--background-color);
}
p {
  font-size: 0.875rem;
  color: var(--text-icon-color);
  hyphens: auto;
}

*:focus {
  outline: 0;
}

</style>
