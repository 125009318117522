<template>
  <div class="home-map">
    <transition name="fade">
      <LoadingScreen v-if="!showMap"/>
    </transition>
    <MglMap :accessToken="accessToken" :mapStyle="mapStyle" @load="onMapLoad" :zoom.sync="$store.state.mapState.zoom" :pitch.sync="$store.state.mapState.pitch" :center.sync="$store.state.mapState.center" :bearing.sync="$store.state.mapState.bearing" class="home-map">
    <!-- use our custom bike markers on the map -->
    <MglMarker v-for="marker in mapMarker" :key="marker.phone" :coordinates="[marker.long, marker.lat]" :color="markerColor(marker)">
      <MglPopup anchor="top">
          <div class="popup-body">
            <h3 class="popup-text">
              {{checkFav(marker)}}
              <b-icon icon="lightning" v-b-tooltip.hover="'Ping'" style="cursor: pointer;" @click="requestPing(marker.phone)"/>
              <b-icon icon="geo-alt" v-b-tooltip.hover="'Position'" style="cursor: pointer;" @click="requestPosition(marker)"/>
            </h3>
            <span class="popup-text" v-if="marker.floor">Stockwerk: {{marker.floor}}</span><br v-if="marker.floor">
            <span class="popup-text">Telefon: <a :href="`tel:${marker.phone}`">{{marker.phone}}</a></span><br>
            <span class="popup-text">Handyakku: {{marker.power}}%</span><br>
            <span class="popup-text">Höhe: {{Math.round(marker.altitude)}}m</span><br>
            <span class="popup-text">Geschwindigkeit: {{marker.speed}}</span><br>
            <span class="popup-text">Genauigkeit: {{marker.speedAccuracy}}</span><br>
            <span class="popup-text">Länge: {{marker.long}}</span><br>
            <span class="popup-text">Breite: {{marker.lat}}</span><br>
            <span class="popup-text">Genauigkeit: {{Math.round(marker.accuracy)}}</span><br>
            <span class="popup-text">Stand: {{getDate(marker.updatedTs)}}</span><br>
            <span class="popup-text"><small>zuletzt gesehen {{getReadableDate(marker.pongTs || 0)}}</small></span>
          </div>
        </MglPopup>
    </MglMarker>
    
    <MglGeolocateControl v-if="showControls" />
    <MglNavigationControl v-if="showControls" />
  </MglMap>
  <MenuButton v-if="showControls"/>
  </div>
</template>

<script>
  import Mapbox from "mapbox-gl";
  import moment from 'moment';
  import LoadingScreen from '../components/LoadingScreen.vue';
  import MenuButton from '../components/MenuButton';

  import {
    MglMap,
    MglMarker,
    MglPopup,
    MglGeolocateControl,
    MglNavigationControl,
  } from "vue-mapbox";
  export default {
    name: 'MapComponent',
    components: {
      LoadingScreen,
      MenuButton,
      MglMap,
      MglMarker,
      MglPopup,
      MglGeolocateControl,
      MglNavigationControl,
    },
    data() {
      return {
        accessToken: "pk.eyJ1IjoiaXRtci1kZXYiLCJhIjoiY2todzR3NHZ0MXQ0ZzJxa3pwbWxhaGs1YiJ9.5KOFqHH7eYCr86icL8EJLA",
        mapStyle: 'mapbox://styles/itmr-dev/ckmw1rd3512xq17sago3ojfji',
        showMap: false,
        showControls: false,
        showTreePopup: false,
        mapMarker: [],
        zoom: undefined,
      }
    },
    methods: {
      async onMapLoad(/*event*/) {
        // const asyncActions = event.component.actions
        if (this.$store.state.mapLoaded) {
          this.showControls = true;
          setTimeout(() => this.showMap = true, 250);
        } else {
          this.showMap = true;
          /*await asyncActions.flyTo({
            center: [13.4546841, 52.5142626],
            zoom: 14,
            speed: 0.5
          })
          .then(() => setTimeout(() => this.showControls = true, 500));
          */
          setTimeout(() => this.showControls = true, 500);
          this.$store.state.mapLoaded = true;
        }
      },
      /*selectBike(bike) {
        this.$store.state.bike = bike;
        this.$router.push('/bikeInfo');
      },*/
      async getMapMarker() {
        // gets all bikes from the database
        const token = await localStorage.getItem('token')
        this.axios.get('map/getMapMarker', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          this.mapMarker = res.data;
        })
        .catch((err) => {
          this.toast('Es ist ein Fehler aufgetreten', 'Fehler beim Laden der Daten', 'danger')
          console.error(err);
        });
      },
      async requestPing(phone) {
        // gets all bikes from the database
        const token = await localStorage.getItem('token')
        this.axios.post('map/requestPing', {
          phone,
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(() => this.toast('Ping angefordert', 'Der Ping wurde von dem Gerät angefordert', 'success'))
        .catch(() => this.toast('Es ist ein Fehler aufgetreten', 'Fehler beim Laden der Daten', 'danger'));
      },
      async requestPosition(marker) {
        const token = await localStorage.getItem('token')
        this.$bvModal.msgBoxConfirm(`Bist du sicher dass du ${marker.preName}s Position anfordern willst?\nDer Nutzer wird über deine Anfrage benachrichtigt.`, {
          bodyTextVariant: 'dark',
        })
          .then(value => {
            if (value) this.axios.post('map/requestPosition', {
              phone: marker.phone,
            }, {
              headers: {
                Authorization: `Bearer ${token}`
              }
            })
            .then(() => this.toast('Position angefordert', 'Die Position wurde von dem Gerät angefordert', 'success'))
            .catch(() => this.toast('Es ist ein Fehler aufgetreten', 'Fehler beim Laden der Daten', 'danger'));
          })
          .catch(() => {
            // An error occurred
          })
      },
      markerColor(marker) {
        if (localStorage.getItem('preName') === 'Marc' &&
          localStorage.getItem('lastName') === 'Rufeis' &&
          marker.preName === 'Mimi' &&
          marker.lastName === 'Boock' &&
          marker.mode !== 'alert') {
            return 'pink';
          }
        switch (marker.mode) {
          case 'alert':
            return 'red';
          case 'startup':
            return 'white';
          case 'normal':
            return 'lightgrey';
          default:
            return 'black';
        }
      },
      checkFav(marker) {
        if (localStorage.getItem('preName') === 'Marc' &&
          localStorage.getItem('lastName') === 'Rufeis' &&
          marker.preName === 'Mimi' &&
          marker.lastName === 'Boock') {
            return 'hny ❤️ ';
        } else {
          return `${marker.preName} ${marker.lastName}`;
        }
      },
      getDate(ts) {
        const d = new Date(ts * 1);
        return d.toLocaleString();
      },
      getReadableDate(ts) {
        return moment(parseInt(ts, 10)).locale('de').fromNow();
      }
    },
    computed: {
    },
    created() {
      // We need to set mapbox-gl library here in order to use it in template
      this.mapbox = Mapbox;
    },
    mounted() {
      this.getMapMarker();
      // updates all bikes on the map every 10 seconds
      setInterval(() => {
        this.getMapMarker();
      }, 10000);
    }
  }
</script>

<style scoped>
  .home-map {
    position: fixed;
    width: 100vw;
    height: 100vh;
  }
  .fade-leave-active {
  transition: opacity .5s;
}
  .fade-leave-to {
    opacity: 0;
  }
  .popup-body {
    margin: .5rem;
    margin-right: 1rem;
  }
  .popup-text {
    color: var(--background-color)
  }
</style>