import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
      title: 'Dashboard - Mimi'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import( '../views/Login.vue'),
    meta: {
      title: 'Anmelden - Mimi'
    }
  },
  {
    path: '/reset',
    name: 'Reset',
    component: () => import( '../views/Reset.vue'),
    meta: {
      title: 'Reset - Mimi'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
