<template>
  <Map/>
</template>

<script>
// @ is an alias to /src
import Map from '../components/Map';

export default {
  name: 'Home',
  components: {
    Map
  }
}
</script>
