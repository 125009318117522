import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router/router'
import store from './store'
import axios from 'axios';
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)
axios.defaults.baseURL = 'https://sosapp.glados.itmr-dev.de/api/v1/'

Vue.config.productionTip = false

// declare global methods
Vue.mixin({
  methods: {
    // shows popup status messages
    toast: function (title, text, variant = null) {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
        autoHideDelay: 2000,
        toaster: 'b-toaster-top-right',
        appendToast: false
      })
    },
    // gets userprofile from the database
    getUserProfile() {
      return new Promise((resolve, reject) => {
        this.axios.get('user/getUserProfile', {
          headers: {
            token: localStorage.getItem('token'),
          }
        })
          .then((res) => {
            this.$store.state.userProfile = res.data;
            resolve();
          })
          .catch((err) => {
            this.toast('Es ist ein Fehler aufgetreten', 'Fehler beim Laden des Nutzerprofils', 'danger')
            router.push('/start')
            console.error(err);
            reject();
          });
      });
    }
  }
})

// check if loggedin
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('token') === null) {
      next({
        name: 'Login',
      });
      this.toast('Es ist ein Fehler aufgetreten', 'Du musst dich zuerst anmelden!', 'danger')
      store.state.loggedIn = false;
    } else {
      next();
      store.state.loggedIn = true;
    }
  } else {
    next();
  }
});


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
