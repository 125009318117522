<template>
  <div style="background-color: var(--background-color); height: 100vh; width: 100vw;">
    <b-container class="min-vh-100 d-flex flex-column">
      <BackButton v-b-toggle.sidebar-menu/>
      <b-row>
        <b-col cols="1" />
        <b-col>
          <h2 style="margin-bottom: 2rem; color: var(--white-color);">Hallo {{this.$store.state.preName}}
          </h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="1" />
        <b-col>
          <p>
            Willkommen im Dashboard der Mimi App.
          </p>
          <p v-if="checkFav()">
            Hey honey, dachte mal ich füll hier den Platz noch n bissle. Also in dem Sinne hab dich lieb! ❤️
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col style="margin-top: 1.5rem; margin-bottom: 1.5rem;">
          <b-row v-if="false">
            <b-col cols="1" />
            <b-col cols="10">
              <hr style="background-color: var(--white-color)">
            </b-col>
            <b-col cols="1" />
          </b-row>
          <b-row class="menuItem" style="margin-top: 0;">
            <b-col cols="1" />
            <b-col cols="10">
              <h3 style="color: var(--white-color); margin-top: 0.7rem; width: 100%;"
                @click="logout">
                <b-icon class="icons" scale="1.5" icon="box-arrow-left" />
                Abmelden
              </h3>
            </b-col>
            <b-col cols="1" />
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import BackButton from '../components/BackButton.vue'
  export default {
    name: 'Menu',
    components: {
      BackButton,
    },
    methods: {
      logout() {
        localStorage.clear();
        this.$router.push('/login');
      },
       checkFav() {
        if (localStorage.getItem('preName') === 'Mimi' &&
          localStorage.getItem('lastName') === 'Boock') {
            return true;
        } else {
          return false;
        }
      },
    }
  }
</script>

<style scoped>
  .menuItem {
    color: var(--white-color);
    margin-top: 1rem;
    cursor: pointer;
  }

  .icons {
    margin-right: 2rem;
  }

  .whitecontainer {
    background-color: var(--white-color);
    border-radius: 0.5rem;
    text-align: center;
  }

  .whitecontainerItems {
    width: 2rem;
    height: 2rem;
  }
</style>